import React from 'react'
import {
  CFSelect,
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { orderPickupButton } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => (
        <CFView>
          <MobileScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/ohcAjE2ZiGGAVDqiE2Jb/locations/NpQJGKNdn1PDjSXYxOuW`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="87%"
                  maxWidth="330px"
                  alt="About"
                />
              </CFView>
            </a>
          </MobileScreen>
          <DefaultScreen>
            <a
              href={`https://order.codefusion.tech/restaurants/ohcAjE2ZiGGAVDqiE2Jb/locations/NpQJGKNdn1PDjSXYxOuW`}
            >
              <CFView hover>
                <CFImage
                  src={orderPickupButton}
                  w="20vw"
                  maxWidth="340px"
                  minWidth="280px"
                  alt="About"
                />
              </CFView>
            </a>
          </DefaultScreen>
        </CFView>
      )}
    </CFSelect>
  )
}
